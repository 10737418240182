<template>
  <div>
    <Viewheader />
    <Viewmain class="viewmain" />
  </div>
</template>

<script>
import Viewheader from "@/components/viewreport/viewheader";
import Viewmain from "@/components/viewreport/viewmain";
export default {
  components: {
    Viewheader,
    Viewmain
  }
};
</script>

<style scoped>
</style>
