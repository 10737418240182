<template>
  <div>
    <van-tabs v-model="active" sticky>
      <van-tab v-for="item in select" :title="item.name" :key="item.id"></van-tab>
    </van-tabs>
    <div v-if="active==0" style="background:#f0f1f5;">
      <div class="box" v-for="msg in msgs" :key="msg.id">
        <div class="box-title">
          <div>
            <span class="box-title-order">订单号</span>
            <span class="box-title-num">{{msg.orderid}}</span>
          </div>
          <span class="box-title-status">{{msg.status}}</span>
        </div>
        <div class="box-content">
          <span class="box-content-title">{{msg.title}}</span>
          <div class="box-content-order">
            <div class="box-content-timer">{{msg.category.name}}</div>
            <div class="box-content-timer">
              时间：
              <span>{{msg.created_at}}</span>
            </div>
          </div>
        </div>
        <div class="box-bottom">
          <span class="box-bottom-price">￥{{msg.price}}</span>
          <div v-if="msg.status=='待支付'" @click="toPay(msg)">
            <div class="paystyle">去支付</div>
          </div>
          <div v-if="msg.status=='检测完成'" @click="toResult(msg)">
            <div class="reportstyle">查看报告</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="active==1" style="background:#f0f1f5;">
      <div class="box" v-for="msg in complete" :key="msg.id">
        <div class="box-title">
          <div>
            <span class="box-title-order">订单号</span>
            <span class="box-title-num">{{msg.orderid}}</span>
          </div>
          <span class="box-title-status">{{msg.status}}</span>
        </div>
        <div class="box-content">
          <span class="box-content-title">{{msg.title}}</span>
          <div class="box-content-order">
            <div class="box-content-timer">{{msg.category.name}}</div>
            <div class="box-content-timer">
              时间：
              <span>{{msg.created_at}}</span>
            </div>
          </div>
        </div>
        <div class="box-bottom">
          <span class="box-bottom-price">￥{{msg.price}}</span>
          <div v-if="msg.status=='待支付'" @click="toPay(msg)">
            <div class="paystyle">去支付</div>
          </div>
          <div v-if="msg.status=='检测完成'" @click="toResult(msg)">
            <div class="reportstyle">查看报告</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="active==2" style="background:#f0f1f5;">
      <div class="box" v-for="msg in payoff" :key="msg.id">
        <div class="box-title">
          <div>
            <span class="box-title-order">订单号</span>
            <span class="box-title-num">{{msg.orderid}}</span>
          </div>
          <span class="box-title-status">{{msg.status}}</span>
        </div>
        <div class="box-content">
          <span class="box-content-title">{{msg.title}}</span>
          <div class="box-content-order">
            <div class="box-content-timer">{{msg.category.name}}</div>
            <div class="box-content-timer">
              时间：
              <span>{{msg.created_at}}</span>
            </div>
          </div>
        </div>
        <div class="box-bottom">
          <span class="box-bottom-price">￥{{msg.price}}</span>
          <div v-if="msg.status=='待支付'" @click="toPay(msg)">
            <div class="paystyle">去支付</div>
          </div>
          <div v-if="msg.status=='检测完成'" @click="toResult(msg)">
            <div class="reportstyle">查看报告</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="active==3" style="background:#f0f1f5;">
      <div class="box" v-for="msg in other" :key="msg.id">
        <div class="box-title">
          <div>
            <span class="box-title-order">订单号</span>
            <span class="box-title-num">{{msg.orderid}}</span>
          </div>
          <span class="box-title-status">{{msg.status}}</span>
        </div>
        <div class="box-content">
          <span class="box-content-title">{{msg.title}}</span>
          <div class="box-content-order">
            <div class="box-content-timer">{{msg.category.name}}</div>
            <div class="box-content-timer">
              时间：
              <span>{{msg.created_at}}</span>
            </div>
          </div>
        </div>
        <div class="box-bottom">
          <span class="box-bottom-price">￥{{msg.price}}</span>
          <div v-if="msg.status=='待支付'" @click="toPay(msg)">
            <div class="paystyle">去支付</div>
          </div>
          <div v-if="msg.status=='检测完成'" @click="toResult(msg)">
            <div class="reportstyle">查看报告</div>
          </div>
        </div>
      </div>
    </div>
    <van-overlay :show="show" @click="show = true">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="block-container">
            <p>微信“搜一搜”【学信检测】</p>
            <p>关注公众号可收到检测结果</p>
            <p>通知，方便随时查看报告。</p>
            <div style="display: flex;justify-content: center;margin-top: 0.33rem;">
              <span
                style="background: #0498fa;color: #fff;padding: 0.05rem 0.37rem;"
                @click="closeshow"
              >关闭</span>
            </div>
          </div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Toast } from "vant";
import { Dialog } from "vant";
import { Overlay } from "vant";
export default {
  data() {
    return {
      msgs: [],
      active: "",
      isToken: false,
      show: false,

      select: [
        {
          id: 1,
          name: "全部"
        },
        {
          id: 2,
          name: "已完成"
        },
        {
          id: 3,
          name: "待付款"
        },
        {
          id: 4,
          name: "其他"
        }
      ]
    };
  },
  created() {
    if (window.sessionStorage.getItem("Token")) {
      this.isToken = true;
      if (!window.sessionStorage.getItem("show")) {
        this.show = true;
        window.sessionStorage.setItem("show", "true");
      }
      this.$http.getReport().then(res => {
        this.msgs = res.data;
        console.log(res, 31);
      });
    } else {
      this.isToken = false;
      Dialog.confirm({
        title: "提示",
        message: "你还没有登录，即将跳转登录页",
        width: "80%"
      }).then(() => {
        this.$router.push("/Login");
      });
    }
  },
  computed: {
    complete() {
      return this.msgs.filter(item => {
        return item.status == "检测完成";
      });
    },
    payoff() {
      return this.msgs.filter(item => {
        return item.status == "待支付";
      });
    },
    other() {
      return this.msgs.filter(item => {
        return item.status !== "待支付" && item.status !== "检测完成";
      });
    }
  },
  // created() {
  //   if (window.sessionStorage.getItem("Token")) {
  //     this.isToken = true;
  //     this.$http.getReport().then(res => {
  //       this.msgs = res.data;
  //       console.log(res, 31);
  //     });
  //   } else {
  //     this.isToken = false;
  //   }
  // },
  watch: {
    active(v) {
      console.log(v, 123);
    }
  },
  methods: {
    closeshow() {
      this.show = false;
    },
    toPay(payMsg) {
      this.$router.push({
        path: "/pay",
        query: {
          id: payMsg.id,
          title: payMsg.title,
          writer: payMsg.writer,
          words: payMsg.words,
          price: payMsg.price,
          orderid: payMsg.orderid
        }
      });
    },
    toResult(msg) {
      console.log(msg, 13);
      let id = msg.id;
      let orderid = msg.orderid;
      let title = msg.title;
      let writer = msg.writer;
      let timer = msg.rate;
      let report_pdf_path = msg.report_pdf_path;
      let date_pay = msg.date_pay;
      let bbname = msg.category.name;
      this.$router.push({
        path: "/result",
        query: {
          id: msg.id,
          title: msg.title,
          writer: msg.writer,
          timer: msg.rate,
          orderid: msg.orderid,
          report_pdf_path: msg.report_pdf_path,
          date_pay: msg.date_pay,
          bbname: msg.category.name
        }
      });
    }
  }
};
</script>

<style scoped>
.box {
  background: #fff;
  margin-top: 0.2rem;
  padding: 0.2rem;
  box-sizing: border-box;
}
.box-title {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 0.1rem 0;
  font-size: 0.3rem;
}
.box-title-num {
  color: #0498fa;
  margin-left: 0.1rem;
}
.box-title-order {
  color: #ababab;
}
.box-title-status {
  color: #ee0000;
}
.box-content {
  padding: 0.2rem 0;
  border-bottom: 1px solid #ccc;
}
.box-content-order {
  display: flex;
  justify-content: space-between;
}
.box-bottom {
  font-size: 0.31rem;
  display: flex;
  align-items: center;
  padding-top: 0.15rem;
  justify-content: space-between;
}
.box-title-num {
  color: #0498fa;
  margin-left: 10rpx;
}
.box-bottom-price {
  color: #ee0000;
}
.box-content-title {
  font-size: 0.3rem;
  font-weight: bold;
  margin-bottom: 0.1rem;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.box-content-timer {
  font-size: 0.3rem;
}
.paystyle {
  background-color: #0498fa;
  color: #fff;
  height: 0.4rem;
  line-height: 0.4rem;
  font-size: 0.3rem;
  padding: 0 0.3rem;
}
.reportstyle {
  background-color: #9acd32;
  color: #fff;
  height: 0.4rem;
  line-height: 0.4rem;
  font-size: 0.3rem;
  padding: 0 0.3rem;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  background-color: #fff;
  width: 5.5rem;
  height: 4.3rem;
  display: flex;
  border: 1px solid #ccc;
  justify-content: center;
  align-items: center;
}
.block-container {
  font-size: 0.33rem;
  line-height: 0.5rem;
}
</style>