<template>
  <div class="title">
    <p>查看报告</p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.title p {
  height: 0.8rem;
  background: #228ef7;
  text-align: center;
  line-height: 0.8rem;
  color: #fff;
}
</style>